$primary: #ff5a5f;
$gray: #999999;

nav.navbar-light {
  background-color: white;

  .search-desktop {
    position: relative;
    .fa-search {
      position: absolute;
    }
    form {
      width: 100%;
    }
    .nav-search__input {
      padding-left: 1.5em;
      width: 100%;
    }
  }

  .navbar-toggler,
  .nav-search {
    &:focus {
      outline: none;
    }
  }
  .navbar-toggler {
    .bar {
      display: block;
      height: 1px;
      width: 20px;
      background: $gray;
      margin-bottom: 5px;
      transition: all 300ms;
    }
    &.active {
      .bar {
        &.upper {
          transform: translateY(4px) rotate(-405deg);
        }
        &.middle {
          opacity: 0;
        }
        &.lower {
          transform: translateY(-9px) rotate(405deg);
        }
      }
    }
  }
  .nav-search {
    &__icon {
      margin-bottom: 0;
      svg.fa-2x {
        font-size: 1.4rem;
      }
    }
  }

  .category-search {
    .nav-link,
    .dropdown-item {
      color: $primary;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      font-size: 16px;
      &:focus,
      &:hover {
        color: darken($primary, 15%);
      }
      &:after {
        transition: all 0.2s;
      }
      &[aria-expanded='true'] {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
    .dropdown-menu {
      border: none;
      box-shadow: none;
    }
  }
  .navbar-brand {
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.4rem;
  }
}
.navbar-light {
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
  }
}
@media (min-width: 992px) {
  nav.navbar-light {
    padding: 0.5em 0 !important;
  }
  .navbar-nav .nav-link {
    padding-top: 0.3em;
    padding-bottom: 0.3em;
  }
}
